.contact {
  margin-top: 10%;
  margin-bottom: 5%;
  .contact-form {
    box-shadow: 0 4px 20px rgba(34, 34, 34, 0.1);
    padding: 55px;
    background: #fff;
    h1 {
      font-size: 30px;
      color: black;
      font-weight: 700;
      margin-bottom: 25px;
      text-align: center;
    }
    input.form-control {
      height: 50px;
    }
  }
  
  @media screen and (max-width: 767px) {
    margin-top: 25%;
    margin-bottom: 10%;
  }
}

.modal-content .p-5.modal-body .closePopup {
  background: transparent;
  border: none;
  position: absolute ;
  color: gray;
  right: 25px;
  top: 20px;
  font-size: 20px;
}