.dashboard-cards .card {
  width: 24%;
  margin-right: 14px;
  border-radius: 10px;
  background: transparent;
  margin-bottom: 15px;
}

.bold
{
  font-weight: 650;
}
.card-content {
  padding: 20px 30px;
  background-color: #fff;
  /* box-shadow: 0 2px 7px 4px #e5e5e5; */
  border-radius: 8px;
}
.semibold
{
  font-weight: 700;
}
a{
  text-decoration: none !important;
}
.f-13
{
  font-size: 13px;
}
.f-20
{
  font-size: 20px;
}
.f-16
{
  font-size: 16px;
}
.orange {
  color: #060b16d3;
}
a.menu-link {
  padding: 10px 14px;
}
input:focus
{
  outline: none !important;
}

.static-admin-chart svg {
  width: 100% !important;
}