.paymentComplete {
    padding-top: 90px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    background-color: white;
}

.paymentComplete>img {
    width: 115px;
}