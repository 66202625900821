.header {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    background-color: #f8f9fa;
    border: 0;
    //transition: all .5s ease;
    .nav-link {
        color: #3c4858;
    }
    .nav-link:hover {
        color: #6772e5 !important;
    }
    .navbar-nav .nav-active {
        color: #6772e5 !important;
    }
    .navbar-toggle .lines {
        width: 25px;
        display: block;
        position: relative;
        margin: 28px 20px;
        height: 18px;
    }
    .menu-extras {
        float: right;
    }
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
        height: 74px;
    }
    .navbar-brand {
        img {
            height: 7rem;
        }
    }
    // .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited, .btn-secondary:focus{
    //     background: #9a08cf;
    // }
    .logo {
        float: left;
        color: #3c4858 !important;
    }
    .stub-button {
        float: right;
        line-height: 68px;
    }
    .navigation-menu {
        display: flex;
        justify-content: center;
        li > a {
            display: block;
            color: #3c4858;
            font-size: 13px;
            background-color: transparent !important;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 24px;
            text-transform: uppercase;
            //transition: all .5s;
            font-family: 'Nunito', sans-serif;
            padding-left: 15px;
            padding-right: 15px;
            margin: 0 10px;
        }
        li.active > .nav-link {
            color: #336bfe;
        }
    }
    // >=992 width
    @media (min-width: 992px) {
        .stub-button {
            padding-left: 15px;
            margin-left: 15px;
        }
    }
    // <=991 width
    @media (max-width: 991px) {
        .menu-extras .menu-item {
            border-color: #8492a6;
        }
        box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        min-height: 74px;
        .stub-button {
            display: none;
        }
        .collapse.show {
            position: absolute;
            top: 74px;
            left: 0;
            width: 100%;
            height: auto;
            padding-bottom: 0;
            overflow: auto;
            border-top: 1px solid #f1f3f9;
            border-bottom: 1px solid #f1f3f9;
            background-color: #fff;
            max-height: 400px;
        }
        .navigation-menu > li {
            float: none;
            margin: 0;
        }
    }
    //<=767 width
    @media screen and (max-width: 767px) {
        .navbar-brand {
            img {
                height: 5rem;
                margin-top: -10px;
            }
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
