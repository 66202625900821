@font-face {
    font-family: 'Arial';
    src: url('./fonts/arial.ttf') format('ttf');
}

@font-face {
    font-family: 'OCR';
    src: url('./fonts/OCR-ABT.ttf') format('ttf');
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

#w2-form {
    position: relative;
    width: 845px;
    height: 600px;
    font-size: small;

    input {
        width: 100%;
        height: 15px;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        object-fit: cover;
    }

    .ssn {
        position: absolute;
        top: 6%;
        left: 23%;
    }
    .ein {
        position: absolute;
        top: 12%;
        left: 4%;
    }
    .e-name {
        position: absolute;
        top: 20%;
        left: 4%;
    }
    .e-address {
        position: absolute;
        top: 25%;
        left: 4%;
    }
    .e-zip-code {
        position: absolute;
        top: 30%;
        left: 4%;
    }
    .control-number {
        position: absolute;
        top: 36.4%;
        left: 4%;
    }

    .e-name2 {
        position: absolute;
        top: 43%;
        left: 28%;
    }
    .e-address2 {
        position: absolute;
        top: 57%;
        left: 28%;
    }
    .e-zip-code2 {
        position: absolute;
        top: 62%;
        left: 28%;
    }
    .state {
        position: absolute;
        top: 69.5%;
        left: 2%;
        width: 20px;
    }
    .wages {
        position: absolute;
        top: 12%;
        left: 55%;
        width: 15%;
    }
    .federel-tax {
        position: absolute;
        top: 12%;
        width: 15%;
        left: 77%;
    }
    .social-tax {
        width: 15%;
        position: absolute;
        top: 18%;
        left: 77%;
    }
    .social-wages {
        width: 15%;
        position: absolute;
        top: 18%;
        left: 55%;
    }
    .medicare-wages {
        width: 15%;
        position: absolute;
        top: 24.3%;
        left: 55%;
    }
    .madicare-tax {
        width: 15%;
        position: absolute;
        top: 24.3%;
        left: 77%;
    }
    .social-tips {
        width: 15%;
        position: absolute;
        top: 30.5%;
        left: 55%;
    }
    .allocated-tips {
        width: 15%;
        position: absolute;
        top: 30.5%;
        left: 77%;
    }
    .care-benefits {
        position: absolute;
        width: 15%;
        top: 36.5%;
        left: 77%;
    }
    .e-id-number {
        position: absolute;
        top: 69.5%;
        width: 160px;

        left: 7%;
    }
    .state-wages-tips {
        position: absolute;
        top: 69.5%;
        left: 30%;
        width: 100px;
    }
    .income-tax {
        position: absolute;
        top: 69.5%;
        left: 46%;
        width: 100px;
    }
    .local-wages {
        position: absolute;
        top: 69.5%;
        left: 60%;
        width: 100px;
    }
    .local-income-tax {
        position: absolute;
        top: 69.5%;
        width: 100px;
        left: 75%;
    }
    .locality-name {
        position: absolute;
        width: 50px;

        top: 69.5%;
        left: 90%;
    }
    .other {
        position: absolute;
        top: 55%;
        left: 54%;
    }
    .non-qualified-plans {
        position: absolute;
        top: 42%;
        left: 54%;
    }
    .tax-code-a {
        position: absolute;
        top: 42.5%;
        left: 78%;
        width: 20px;
    }
    .tax-code-b {
        position: absolute;
        top: 48.5%;
        left: 78%;
        width: 20px;
    }
    .tax-code-c {
        position: absolute;
        top: 54%;
        left: 78%;
        width: 20px;
    }
    .tax-code-d {
        position: absolute;
        top: 60%;
        left: 78%;
        width: 20px;
    }
    .tax-code-amount-a {
        position: absolute;
        top: 42.5%;
        width: 100px;
        left: 85%;
    }
    .tax-code-amount-b {
        position: absolute;
        top: 48.5%;
        width: 100px;
        left: 85%;
    }
    .tax-code-amount-c {
        position: absolute;
        top: 54%;
        width: 100px;
        left: 85%;
    }
    .tax-code-amount-d {
        position: absolute;
        top: 60%;
        width: 100px;
        left: 85%;
    }
    .checkbox-1 {
        position: absolute;
        top: 48.8%;
        width: 16px;
        left: 56.5%;
    }
    .checkbox-2 {
        position: absolute;
        top: 48.8%;
        width: 16px;
        left: 62.9%;
    }
    .checkbox-3 {
        position: absolute;
        top: 48.8%;
        width: 16px;
        left: 69.4%;
    }
    .suff {
        position: absolute;
        top: 43%;
        left: 49%;
        width: 40px;
    }
}
