.skipbutton22 {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}
.passworddiv{
  position: relative;
}

.password-toggle-icon{
  position: absolute;
  right: 20px;
  top: 30%; 
  cursor: pointer;
}
.text-center {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}