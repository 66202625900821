.table-head {
    background-color: #F3F2F7 !important;
}
.table-view {
    width: 100% !important;
    overflow-x: auto;
}
.table-head th {
    color: black;
    font-weight: 600;
}

table{
    border: none !important;
}
span.table-img {
    display: flex;
    align-items: center;
    gap: 5px;
}
.main-table table tbody tr  {
    border-top: 1px solid #DFDFDF;
}

.main-table table tbody tr:last-child {
    border-bottom: 1px solid #DFDFDF;
}

.table-img img {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 3px;
}

.table-center {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Use 100% of the viewport height */
  }
.fort{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}