.smallBtn{
  width: 160px !important;
  padding-right: 70%;
  display: flex;
  justify-content: flex-end !important;
}
.nav-pills .nav-link.active {
  background-color: #5979df !important;
}
.crtImg {
  border: 1px solid #000;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  cursor: pointer;
}
.hellImg {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
}
span.table-category-img {
  display: flex;
  align-items: center;
  gap: 10px;
}
.listImages {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}
.printicon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 70px !important;
}