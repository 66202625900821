$primary-color-1: #336bfe;
$primary-color-2: #6772E5;
$secondary-color-1: #ffffff;
$bg-color: #f8f9fc;
$bg-light: #f8f9fc;

@font-face {
    font-family: 'Nunito';
    src: URL('./assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
}
/* make the customizations */
$theme-colors: (
    'info': tomato,
    'danger': teal,
    'primary': $primary-color-1,
);
@import '~bootstrap/scss/bootstrap';
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $bg-light !important;
}

.btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    font-weight: 600;
    border-radius: 6px;
}
.btn-outline-primary {
    color: #6772e5;
    border-color: #6772e5;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #6772e5 !important;
    border-color: #6772e5 !IMPORTANT;
}
.btn-primary {
    border: 1px solid $primary-color-1;
    background: $primary-color-1;
    box-shadow: 0 3px 5px 0 $primary-color-1;
}
.text-primary 
{
    color: $primary-color-2 !important;
}
.btn-secondary {
    border: 1px solid $primary-color-2;
    background: $primary-color-2;
    box-shadow: 0 3px 5px 0 rgb(143 143 143 / 30%);
}
.btn-secondary:hover {
    background: #6772E5;
}
.btn-secondary:not(:disabled):not(.disabled):active {
    background: #6772E5;
    border-color: #6772E5;
}
.btn-secondary:focus {
    background-color: #6772E5;
    border-color: #6772E5;
    box-shadow: none;
}
a {
    text-decoration: none !important;
}
.bg-light {
    background-color: #f8f9fc !important;
}
.bg-half,
.bg-half-170 {
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: 50%;
}
.bg-half-170 {
    padding: 140px 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Nunito', sans-serif;
    line-height: 1.5;
    font-weight: 600;
}

.fea {
    stroke-width: 1.8;
}
.fea.icon-sm {
    height: 16px;
    width: 16px;
}
.form-group .icons {
    position: absolute;
    top: 13px;
    left: 18px;
}

.form-radio {
    font-size: 20px;
}

.nav-sticky {
    background: #ffffff;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.badge.badge-pill {
    font-weight: 400;
    border-radius: 12px;
}
.badge-soft-primary {
    background-color: rgba(51, 107, 254, 0.1) !important;
    border: 1px solid rgba(51, 107, 254, 0.1) !important;
    color: #6772E5 !important;
}

//global media queries
@media screen and (max-width: 767px) {
    .bg-half-170 {
        padding: 150px 0;
    }
}
.carousel .control-dots {
    bottom: -25px !important;
}
