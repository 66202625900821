.scrollToTop {
  background: rgb(103 114 229);
  border-radius: 25px;
  padding: 14px;
  transition: all 1s;
  color: #fff;
}
.scrollToTop i {
  font-size: 20px;
}
.bg-primary {
  background-color: #6772e5 !important;
}
.progress .progress-bar {
  background-color: #6772e5 !important;
}
.navbar-nav .nav-active {
  color: #6772e5 !important;
  background-color: red !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #6772e5 !important;
  background-color: #6772e5 !important;
}
.scrollToTop:hover {
  background: rgba(0, 0, 0, 0.9);
}
.footer .footer-bottom a {
  color: #6772e5;
}
.scrollToTop:hover i {
  color: #fff;
}

#root {
  background: #f8f9fc !important;
  overflow-x: hidden;
  width: 100vw;
}

@media(max-width: 767px)
{
  .stepOne {
    width: 100% !important;
    padding: 25px 10px !important;
}
}