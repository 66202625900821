.helloWorld {
    /* margin: 100; */
    background-color: red !important;
    padding: 10px;
    margin-top: 100px;
}
.stepOne {
    width: 50%;
    margin: auto;
    background: white;
    padding: 25px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
}
.stepTwo {
    width: 100%;
    background: white;
    padding: 25px;
    padding-bottom: 40px;
}
.mainTypo {
    font-style: italic;
    font-size: 13px;
}
#red {
    color: red;
}
.W2FormData {
    font-size: 10;
    font-weight: bold;
}
