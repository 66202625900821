.features{
    margin-top: 7%;
    i{
        font-size: 50px;
        padding-top: 10px;
        transition: 1s;
        color: #6772e5;
        padding-right: 5px;
    }
    hr{
        margin-bottom: 9%;
    }
}