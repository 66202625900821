.PayStubForm {
    // width: 100%;
    margin-top: 7%;
    margin-bottom: 5%;

    .help-icon {
        background: #e2e4e86e;
        padding: 3px 7px;
        border-radius: 18px;
        font-size: 15px;
        text-align: center;
        margin-left: 5px;
    }

    ol {
        text-align: center;
    }

    .formStep {
        width: 50%;
        margin: auto;
        background: white;
        padding: 25px;
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
    }

    .progressBar {
        width: 50%;
        margin: auto;
        background: white;
        padding: 25px;
    }

    .form-group.required .label-input::after {
        content: '*';
        color: red;
        margin-left: 5px;
    }

    .image-input {
        padding-bottom: 35px;
        cursor: pointer;
    }

    .stup-template-img {
        width: 100%;
    }

    .greentick {
        color: #5cd91e;
        font-size: 20px;
        margin-left: 10px;
    }

    .formStep3 {
        .react-datepicker-wrapper {
            display: block;
        }
    }

    .myProgressBar {
        width: 80%;
        margin: auto;
        text-align: center;
        margin-bottom: 20px;

        ul {
            margin-left: -35px;
        }

        li {
            width: 2em;
            height: 2em;
            text-align: center;
            line-height: 2em;
            border-radius: 1em;
            background: #6772e5;
            margin: 0 1em;
            display: inline-block;
            color: white;
            position: relative;
            cursor: pointer;
        }

        li::before {
            content: '';
            position: absolute;
            top: 0.9em;
            left: -4em;
            width: 4em;
            height: 0.2em;
            background: #6772e5;
            z-index: -1;
        }

        li:first-child::before {
            display: none;
        }

        .active {
            background: #6772e5;
        }

        .active~li {
            background: #696b7f;
        }

        .active~li::before {
            background: #6772e5;
        }
    }

    .addPayDate {
        cursor: pointer;
        color: #6772e5;
    }

    .cross {
        cursor: pointer;
        color: #ff00007a;
        font-size: 20px;
        vertical-align: bottom;
    }

    .group-border {
        border: 1px solid #80808029;
        border-radius: 35px;
        padding: 10px;
    }

    @media only screen and (max-width: 400px) {
        .hide-in-mobile {
            display: none;
        }
    }

    @media only screen and (max-width: 767px) {
        margin-top: 25%;
        margin-bottom: 12%;

        .formStep {
            width: 100%;
            margin: auto;
        }

        .progressBar {
            width: 100%;
            margin: auto;
        }

        .myProgressBar {
            width: 100%;
            margin: auto;
        }
    }
}

.form-control.is-invalid {
    border: 1px solid red !important;
    background-image: none !important;
    box-shadow: none !important;
}

#passwordHelp {
    color: red !important;
}

.sigCanvas {
    border: 1px solid black;
    border-radius: 7px;
    margin: 30px;
    height: 80vh;
}

.signature-preview {
    width: 100%;
    height: 100px;
    margin: 10px;
    object-fit: contain;
}

.btn-outline-new {
    color: #6772E5 !important;
    border-color: #6772E5 !important;
  }

  .btn-outline-new:hover {
    color: #fff !important;
    background-color: #6772E5;
    border-color: #6772E5;
}

.paydate-label {
    font-size: 14px;
    color: grey;
}