.home {
    ///////////////////////////
    //Hero
    ///////////////////////////
    .hero {
        
    }
    .badge.badge-pill {
        font-weight: 400;
        border-radius: 12px;
    }
    .badge-soft-primary {
        background-color: rgba(51, 107, 254, 0.1) !important;
        border: 1px solid rgba(51, 107, 254, 0.1) !important;
        color: #6772E5 !important;
    }
    .title-heading .heading {
        font-size: 45px !important;
        letter-spacing: 1px;
    }
    .mb-3,
    .my-3 {
        margin-bottom: 1rem !important;
    }
    .form-check-inline > label {
        color: gray;
    }
    .help-icon {
        background: #e2e4e86e;
        padding: 3px 7px;
        border-radius: 18px;
        font-size: 14px;
        margin-left: 5px;
    }
    a.settings {
        box-shadow: 2px 2px 7px rgba(183, 7, 247, 0.35);
        height: 41px;
        position: absolute;
        top: -38px;
        width: 84px;
        padding: 8px;
        border-radius: 0 30px 30px 0;
        background: none repeat scroll 0 0 #fff;
    }
    .shadow {
        box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
    }
    @media (max-width: 768px) {
        .title-heading .heading {
            font-size: 35px !important;
        }
    }
    ///////////////////////////
    //Introduction
    ///////////////////////////
    .introduction {
        margin-top: 14%;
    }
}
